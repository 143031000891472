@import url("https://fonts.googleapis.com/css2?family=Nunito+Sans:wght@300;400;700&display=swap");

:root {
  --dark-grey-start: #3e3e3e;
  --dark-grey-mid: #2c2b2b;
  --dark-grey-end: #121111;
}

body {
  color: white;
  font-family: "Nunito Sans", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(
    to right,
    var(--dark-grey-start),
    var(--dark-grey-mid),
    var(--dark-grey-end)
  );
  background-size: 200% 100%;
  animation: gradientShift 3s ease infinite alternate;
}

@keyframes gradientShift {
  0% {
    background-position: 0% 50%;
  }
  100% {
    background-position: 100% 50%;
  }
}

.App {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  flex-direction: column;
  color: white;
  font-family: "Nunito Sans", sans-serif;
  width: 100vw;
  overflow-x: hidden;
  overflow-y: auto;
}

.content {
  text-align: center;
  max-width: 800px;
  padding: 20px;
  z-index: 2;
  position: relative;
  border-radius: 10px;
  width: 80%;
}

h1 {
  font-size: 2.5rem;
}

span {
  padding-right: 15px;
}

.name-gradient {
  background: linear-gradient(to right, #4a90e2, #9013fe);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.programming-languages {
  margin-top: 20px;
}

.programming-languages code {
  background: #272822;
  color: #f8f8f2;
  padding: 5px 10px;
  margin-right: 5px;
  border-radius: 10px;
  display: inline-block;
}

.social-links {
  margin-top: 20px;
  display: flex;
  justify-content: center;
}

.social-links a {
  margin: 0 20px;
  transition: transform 0.3s ease;
  color: whitesmoke;
}

.social-links a:hover {
  transform: scale(1.2);
  color: #4a90e2;
}

.social-links svg {
  width: 25px;
  height: 25px;
}

.certifications {
  margin-top: 20px;
}

@keyframes zoom {
  0%,
  100% {
    transform: scale(1);
  }
  50% {
    transform: scale(0.9);
  }
}

.certifications a {
  display: inline-block;
  color: whitesmoke;
  text-decoration: none;
  animation: zoom 3s ease-in-out infinite;
}

.certifications a:hover {
  color: #4a90e2;
  text-decoration: underline;
}

.outreach-message {
  padding-top: 32px;
}

.navbar {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}

.nav-link {
  margin: 0 20px;
  color: white;
  text-decoration: none;
  font-size: 1.2rem;
  padding: 10px 15px;
}

.nav-link:hover,
.nav-link:focus {
  color: #4a90e2;
  transform: scale(1.1);
}

.projects-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 20px;
  width: 100%;
  padding: 20px;
  overflow-y: auto;
}

.project-card {
  background: #ffffff;
  color: #333;
  width: calc((100% / 3) - 40px);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 20px;
  transition: transform 0.3s;
}

.project-card:hover {
  transform: translateY(-5px);
}

.project-image {
  width: 100%;
  height: 200px;
  object-fit: cover;
}

.project-info {
  padding: 15px;
  text-align: center;
}

.project-info h3 {
  margin-top: 0;
}

.project-link {
  margin: 12px;
}

.project-info a {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  color: #333;
  text-decoration: none;
}

.project-info a:hover {
  text-decoration: underline;
}

.project-info svg {
  margin-right: 8px;
}

@media (max-width: 768px) {
  .App {
    justify-content: flex-start;
    padding-top: 30px;
  }

  body {
    height: auto;
    min-height: 100vh;
    overflow-y: auto;
  }

  .content {
    padding: 10px;
    width: 90%;
  }

  .navbar {
    padding: 5px 0;
    font-size: 1rem;
  }

  .nav-link {
    padding: 8px 10px;
    font-size: 1rem;
  }

  .social-links svg,
  .project-info svg {
    width: 20px;
    height: 20px;
  }

  .project-card {
    margin-bottom: 20px;
  }

  h1 {
    font-size: 2rem;
  }

  .social-links {
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 0 10px;
  }

  .social-links a,
  .programming-languages code {
    margin: 5px;
    padding: 3px 8px;
  }

  .certifications a,
  .programming-languages code {
    font-size: 0.9rem;
  }

  .outreach-message {
    padding-top: 20px;
  }

  .project-card {
    width: calc((100% / 3) - 40px);
    padding: 15px;
    margin: 0 15px;
  }

  .project-info h3,
  .project-info p {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .App {
    justify-content: flex-start;
    padding-top: 20px;
  }

  body {
    height: auto;
    min-height: 100vh;
    overflow-y: auto;
  }

  .content {
    padding: 10px;
    width: 95%;
  }

  .navbar {
    padding: 5px 0;
    font-size: 0.9rem;
  }

  .nav-link {
    padding: 8px 10px;
    font-size: 0.9rem;
  }

  .social-links svg,
  .project-info svg {
    width: 18px;
    height: 18px;
  }

  .project-card {
    margin-bottom: 20px;
  }

  h1 {
    font-size: 1.3rem;
  }

  .social-links {
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 0 5px;
  }

  .social-links a,
  .programming-languages code {
    margin: 2px;
    padding: 2px 6px;
  }

  .certifications a,
  .programming-languages code {
    font-size: 0.8rem;
  }

  .outreach-message {
    padding-top: 20px;
  }

  .project-card {
    width: 100%;
    padding: 8px;
    margin: 0 15px;
  }

  .project-info h3,
  .project-info p {
    font-size: 0.9rem;
  }
}
@media (max-width: 375px) {
  .App {
    justify-content: flex-start;
    padding-top: 20px;
  }

  body {
    height: auto;
    min-height: 100vh;
    overflow-y: auto;
  }

  .content {
    padding: 8px;
    width: 92%;
  }

  .navbar {
    padding: 3px 0;
    font-size: 0.85rem;
  }

  .nav-link {
    padding: 6px 8px;
    font-size: 0.85rem;
  }

  .social-links svg,
  .project-info svg {
    width: 14px;
    height: 14px;
  }

  .project-card {
    margin-bottom: 10px;
  }

  h1 {
    font-size: 1.2rem;
  }

  .social-links {
    flex-wrap: wrap;
    justify-content: space-evenly;
    padding: 0 3px;
  }

  .social-links a,
  .programming-languages code {
    margin: 1px;
    padding: 2px 5px;
  }

  .certifications a,
  .programming-languages code {
    font-size: 0.75rem;
  }

  .outreach-message {
    padding-top: 15px;
  }

  .project-card {
    width: calc(100% - 10px);
    padding: 6px;
    margin: 0 15px;
  }

  .project-info h3,
  .project-info p {
    font-size: 0.85rem;
  }
}
